import React from "react";
import { Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Ologo from "./../Assets/images/O_logo.jpg";
import cardio from "./../Assets/images/Departments/Cardiology.png";
import urology from "./../Assets/images/Departments/Urology.png";
import Pulmonology from "./../Assets/images/Departments/Pulmonology.png";
import ENT from "./../Assets/images/Departments/ENT-&-Audiology.png";
import Obstetrics from "./../Assets/images/Departments/Obstetrics-&-Gynecology.png";
import Pediatric from "./../Assets/images/Departments/Pediatric.png";
import Internal from "./../Assets/images/Departments/Internal-Medicine.png";
import General from "./../Assets/images/Departments/General-Medicine.png";
import Neurology from "./../Assets/images/Departments/Neurology.png";
import Oncology from "./../Assets/images/Departments/Oncology.png";
import Dermatology from "./../Assets/images/Departments/Dermatology.png";
import Ophthalmology from "./../Assets/images/Departments/Ophthalmology.png";
import Orthopaedic from "./../Assets/images/Departments/Orthopedic.png";
import Physiotherapy from "./../Assets/images/Departments/Physiotherapy-&-Rehabilitation.png";
import Dentistry from "./../Assets/images/Departments/Dentistry.png";
import Orthodontics from "./../Assets/images/Departments/Orthodontics.png";
import laboratory from "./../Assets/images/Departments/Laboratory.png";
import Pharmacy from "./../Assets/images/Departments/Pharmacy.png";
import Radiology from "./../Assets/images/Departments/Radiology.png";
import Dietician from "./../Assets/images/Departments/Dietician-and-Nutrition.png";
import Emergency from "./../Assets/images/Departments/Emergency-&-Urgent-Care.png";
import Hour from "./../Assets/images/Departments/24-Hrs-.Clinics.png";
import "./../Assets/css/pagescss/Departmentpage.css";

function Departmentspage() {
  return (
    <>
      <div className="Homepage">
        <div className="Homebanner-container">
          <div className="Departmentbanner"></div>
          <div className="border"></div>
        </div>
      </div>
      <div className="department-section">
        <Grid
          container
          xl={12}
          display={"flex"}
          justifyContent={"center"}
          mt={8}
        >
          <Grid
            display={"flex"}
            alignItems={"center"}
            sx={{ color: "#171859" }}
          >
            <img src={Ologo} alt="o-logo" width={"40px"} height={"40px"} />
            <Typography id="text-30-600-15-Poppins">UR </Typography>
            <Typography id="text-30-600-15-Poppins" color="#B07E2C" ml={1}>
              DEPARTMENTS{" "}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={8} padding={"80px"} sx={{ color: "#171859" }}>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img src={cardio} alt="o-logo" width={"40px"} height={"40px"} />
              <Typography id="text-20-600-15-Poppins">Cardiology</Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              The cardiology department specializes in diagnosing, treating, and
              preventing heart-related diseases and conditions. It offers
              various services, including cardiac evaluations and treatment of
              coronary artery disease, arrhythmias, heart failure, and
              hypertension. Our department is equipped with state-of-the-art
              diagnostic tools like ECGs, echocardiograms, and stress tests and
              provides advanced treatments such as angioplasty, stent placement,
              and heart surgery. The team comprises highly trained
              cardiologists, cardiac surgeons, and specialized nursing staff
              working together to offer comprehensive, patient-centered care.
            </Typography>
          </Grid>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img src={urology} alt="o-logo" width={"40px"} height={"40px"} />
              <Typography id="text-20-600-15-Poppins"> Urology</Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              The Urology Department specializes in diagnosing and treating
              conditions related to the urinary tract and male reproductive
              system. It offers comprehensive services for both men and women,
              addressing issues such as urinary incontinence, kidney stones,
              urinary tract infections, and prostate problems. The department
              has advanced diagnostic and surgical technologies, including
              minimally invasive procedures like laparoscopy and laser
              treatments. Specialists in the department collaborate with other
              medical experts to provide holistic care, focusing on patient
              comfort and successful long-term outcomes.
            </Typography>
          </Grid>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img
                src={Pulmonology}
                alt="o-logo"
                width={"40px"}
                height={"40px"}
              />
              <Typography id="text-20-600-15-Poppins">Pulmonology</Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              The pulmonology department specializes in diagnosing and treating
              diseases related to the respiratory system, including the lungs
              and airways. It offers care for conditions such as asthma, chronic
              obstructive pulmonary disease (COPD), pneumonia, lung cancer,
              sleep apnea, and pulmonary hypertension. Services typically
              include diagnostic procedures like spirometry, bronchoscopy, and
              imaging, as well as advanced therapies such as pulmonary
              rehabilitation, oxygen therapy, and ventilatory support. The
              department works closely with critical care units for managing
              severe respiratory illnesses and often collaborates with other
              specialties for comprehensive patient care.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={8} padding={"80px"} sx={{ color: "#171859" }}>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img src={ENT} alt="o-logo" width={"40px"} height={"40px"} />
              <Typography id="text-20-600-15-Poppins">
                {" "}
                ENT & Audiology
              </Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              Our ENT and Audiology Department specializes in comprehensive care
              for ear, nose, throat, and hearing disorders. Our team of
              experienced ENT surgeons and audiologists provide advanced
              diagnostic services, surgical treatments, and hearing solutions
              for patients of all ages. From treating common conditions like
              sinusitis and ear infections to offering specialized services such
              as cochlear implants and hearing aids, we are committed to
              delivering personalized care with the latest medical technologies.
            </Typography>
          </Grid>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img
                src={Obstetrics}
                alt="o-logo"
                width={"40px"}
                height={"40px"}
              />
              <Typography id="text-20-600-15-Poppins">
                {" "}
                Obstetrics & gynecology
              </Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              The Obstetrics and gynecology department provides comprehensive
              care for women at all stages of life, from routine gynecological
              check-ups to advanced obstetric care. Our team is equipped to
              manage routine and high-risk pregnancies, fertility issues, and
              gynecological conditions. The department focuses on personalized
              care, offering the latest diagnostic and treatment technologies to
              ensure patient comfort and safety.
            </Typography>
          </Grid>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img
                src={Pediatric}
                alt="o-logo"
                width={"40px"}
                height={"40px"}
              />
              <Typography id="text-20-600-15-Poppins">Pediatric</Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              The Pediatric Department offers comprehensive care for infants,
              children, and adolescents. Our expert team of pediatricians
              provides a wide range of services, including preventive care,
              vaccinations, routine check-ups, and treatment for acute and
              chronic illnesses. With a child-friendly environment and
              state-of-the-art medical facilities, we are dedicated to ensuring
              the health and well-being of young patients through personalized,
              compassionate care.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={8} padding={"80px"} sx={{ color: "#171859" }}>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img src={Internal} alt="o-logo" width={"40px"} height={"40px"} />
              <Typography id="text-20-600-15-Poppins">
                Internal Medicine
              </Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              The Internal Medicine department provides comprehensive care for
              adults, focusing on the prevention, diagnosis, and treatment of a
              wide range of illnesses and chronic conditions. Our team of
              skilled internists offers personalized care, emphasizing patient
              education and wellness. We utilize advanced diagnostic tools and
              evidence-based practices to ensure optimal health outcomes for our
              patients.
            </Typography>
          </Grid>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img src={General} alt="o-logo" width={"40px"} height={"40px"} />
              <Typography id="text-20-600-15-Poppins">
                General Surgery
              </Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              The General Surgery department provides comprehensive surgical
              care, specialising in a wide range of procedures, including
              abdominal, hernia, and gastrointestinal surgeries. Our experienced
              surgeons use advanced techniques and cutting-edge technology to
              ensure optimal patient outcomes. We prioritise patient safety,
              offering personalised treatment plans and minimally invasive
              options to promote faster recovery.
            </Typography>
          </Grid>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img
                src={Neurology}
                alt="o-logo"
                width={"40px"}
                height={"40px"}
              />
              <Typography id="text-20-600-15-Poppins">Neurology</Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              Our Neurology department specialises in the diagnosis and
              treatment of a wide range of neurological disorders, including
              stroke, epilepsy, multiple sclerosis, and headaches. Equipped with
              advanced diagnostic tools and staffed by highly skilled
              neurologists, we offer comprehensive care for patients, including
              personalised treatment plans and rehabilitation services to
              support long-term neurological health.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={8} padding={"80px"} sx={{ color: "#171859" }}>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img src={Oncology} alt="o-logo" width={"40px"} height={"40px"} />
              <Typography id="text-20-600-15-Poppins">Oncology</Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              The Oncology Department offers comprehensive cancer care,
              combining advanced diagnostics, personalised treatment plans, and
              multidisciplinary support. Equipped with cutting-edge technology
              and a team of expert oncologists, the department focuses on
              delivering compassionate care with a patient-centred approach. It
              provides treatments such as chemotherapy, radiation therapy,
              and immunotherapy.
            </Typography>
          </Grid>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img
                src={Dermatology}
                alt="o-logo"
                width={"40px"}
                height={"40px"}
              />
              <Typography id="text-20-600-15-Poppins">Dermatology</Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              Our Dermatology department offers comprehensive skin care
              services, specializing in the diagnosis and treatment of a wide
              range of skin, hair, and nail conditions. With a team of expert
              dermatologists and advanced technology, we provide personalized
              solutions for medical, cosmetic, and surgical dermatology,
              ensuring the highest level of patient care.
            </Typography>
          </Grid>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img
                src={Ophthalmology}
                alt="o-logo"
                width={"40px"}
                height={"40px"}
              />
              <Typography id="text-20-600-15-Poppins">Ophthalmology</Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              The Ophthalmology department offers comprehensive eye care
              services, including routine eye exams, advanced diagnostics, and
              surgical treatments. With state-of-the-art technology and a team
              of experienced ophthalmologists, the department specialises in
              cataract surgery, glaucoma management, and cures for retinal
              diseases. The focus is on personalised patient care to ensure
              optimal vision, health, and outcomes.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={8} padding={"80px"} sx={{ color: "#171859" }}>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img
                src={Orthopaedic}
                alt="o-logo"
                width={"40px"}
                height={"40px"}
              />
              <Typography id="text-20-600-15-Poppins">Orthopaedic</Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              The Orthopaedic department offers comprehensive care for
              musculoskeletal conditions, including joint, bone, and soft tissue
              injuries. Our expert team of orthopaedic surgeons specialises in
              advanced treatments, from minimally invasive surgeries to total
              joint replacements, ensuring personalised care and faster
              recovery. We focus on restoring mobility and improving quality of
              life through cutting-edge techniques and
              state-of-the-art technology.
            </Typography>
          </Grid>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img
                src={Physiotherapy}
                alt="o-logo"
                width={"40px"}
                height={"40px"}
              />
              <Typography id="text-20-600-15-Poppins">
                Physiotherapy and Rehabilitation
              </Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              Our Physiotherapy and Rehabilitation Department is dedicated to
              helping patients regain mobility, strength, and independence
              through personalised treatment plans. We offer a wide range of
              services, including physical therapy, occupational therapy, and
              specialised rehabilitation for musculoskeletal, neurological, and
              post-surgical conditions. Our team of experienced therapists
              utilizes advanced techniques and state-of-the-art equipment to
              provide comprehensive care, ensuring optimal recovery and improved
              quality of life for our patients.
            </Typography>
          </Grid>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img
                src={Dentistry}
                alt="o-logo"
                width={"40px"}
                height={"40px"}
              />
              <Typography id="text-20-600-15-Poppins">Dentistry</Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              The Dentistry Department at our multi-speciality clinic offers
              comprehensive oral healthcare services, ranging from preventive
              care to advanced dental treatments. Our expert team of dentists
              and specialists provides personalised care using state-of-the-art
              technology. Services include routine check-ups, cleanings,
              cosmetic dentistry, orthodontics, dental implants, and oral
              surgery. We are committed to maintaining the highest standards of
              patient safety and comfort and to ensuring optimal dental health
              for all ages.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={8} padding={"80px"} sx={{ color: "#171859" }}>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img
                src={Orthodontics}
                alt="o-logo"
                width={"40px"}
                height={"40px"}
              />
              <Typography id="text-20-600-15-Poppins">Orthodontics</Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              Our Orthodontics department offers advanced dental care for
              patients of all ages, specialising in correcting misaligned teeth
              and jaw irregularities. We provide personalised treatments,
              including braces, clear aligners (Invisalign), and other
              state-of-the-art orthodontic solutions. Our team of experienced
              orthodontists is dedicated to improving aesthetics and oral
              health, ensuring optimal results with minimal discomfort. We use
              cutting-edge technology for precise diagnosis and treatment,
              providing faster, more effective outcomes. Whether for early
              intervention in children or comprehensive care for adults, we
              focus on achieving confident, healthy smiles.
            </Typography>
          </Grid>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img
                src={laboratory}
                alt="o-logo"
                width={"40px"}
                height={"40px"}
              />
              <Typography id="text-20-600-15-Poppins">laboratory</Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              Our laboratory department is equipped with state-of-the-art
              technology and offers a wide range of diagnostic tests to support
              accurate and timely medical decisions. It provides clinical
              chemistry, haematology, microbiology, immunology, and molecular
              diagnostics services. Staffed by highly qualified professionals,
              the department ensures precision in testing, prioritises patient
              safety, and maintains strict quality control standards. Rapid
              turnaround times are critical in patient care for both inpatients
              and outpatients, contributing to early diagnosis and treatment
              planning across various specialities.
            </Typography>
          </Grid>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img src={Pharmacy} alt="o-logo" width={"40px"} height={"40px"} />
              <Typography id="text-20-600-15-Poppins">Pharmacy</Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              The Pharmacy department plays a crucial role in providing safe,
              effective, and patient-centered medication management. Staffed by
              highly qualified pharmacists and technicians, the department
              ensures the accurate dispensing of medications, offers expert
              consultation on drug therapies, and collaborates with healthcare
              providers to optimize patient outcomes. Additionally, the
              department promotes patient education on proper medication use,
              supporting overall health and well-being. The pharmacy is equipped
              with the latest technology to ensure efficiency and maintain the
              highest standards of quality and safety.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={8} padding={"80px"} sx={{ color: "#171859" }}>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img
                src={Radiology}
                alt="o-logo"
                width={"40px"}
                height={"40px"}
              />
              <Typography id="text-20-600-15-Poppins">Radiology</Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              The Radiology department offers comprehensive diagnostic imaging
              services using state-of-the-art technology. Our highly trained
              radiologists and technicians provide accurate and timely
              interpretations, utilising advanced equipment such as MRI, CT
              scans, X-rays, ultrasound, and mammography. We ensure precise
              diagnoses to support various medical specialities, enhancing
              patient care through early detection and treatment planning. We
              focus on safety and comfort and follow stringent protocols to
              minimise radiation exposure while delivering high-quality imaging
              results.
            </Typography>
          </Grid>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img
                src={Dietician}
                alt="o-logo"
                width={"40px"}
                height={"40px"}
              />
              <Typography id="text-20-600-15-Poppins">
                Dietician and Nutrition
              </Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              Our Dietician and Nutrition Department offers personalized dietary
              advice and nutrition plans tailored to individual health needs. We
              focus on preventive care, weight management, therapeutic diets for
              chronic conditions, and overall wellness. Our expert dieticians
              provide one-on-one consultations, dietary assessments, and meal
              planning, aiming to improve patient health through balanced,
              nutritious eating. We also offer specialized nutrition programs
              for diabetes, cardiovascular health, digestive disorders, and
              paediatric nutrition, ensuring comprehensive support for every
              stage of life.
            </Typography>
          </Grid>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img
                src={Emergency}
                alt="o-logo"
                width={"40px"}
                height={"40px"}
              />
              <Typography id="text-20-600-15-Poppins">
                Emergency & Urgent Care
              </Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              Our Emergency & Urgent Care department provides 24/7 high-quality
              medical services for patients with acute illnesses and injuries.
              Staffed by experienced physicians, nurses, and paramedics, the
              department is equipped to handle various medical emergencies, from
              trauma and cardiac events to minor injuries. With state-of-the-art
              diagnostic tools and a streamlined triage process, we ensure rapid
              assessment and treatment, prioritising patient safety and comfort.
              We aim to deliver immediate care while minimising wait times and
              providing the best possible outcomes for all patients in urgent
              need.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={8} padding={"80px"} sx={{ color: "#171859" }}>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img src={Hour} alt="o-logo" width={"40px"} height={"40px"} />
              <Typography id="text-20-600-15-Poppins">
                24-Hour Clinic
              </Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              Our 24-Hour Clinic provides round-the-clock medical services
              across multiple specialties, ensuring comprehensive care for
              patients at any time of day or night. With highly skilled doctors,
              advanced diagnostics, and modern treatment options, we offer
              immediate medical attention, from routine check-ups to emergency
              care. Our departments include General Medicine, Paediatrics’,
              Orthopaedics, Obstetrics & Gynaecology, and more, all dedicated to
              delivering high-quality healthcare in a patient-centered
              environment
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Departmentspage;
