import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import logo from "../Assets/images/Logo.jpg";
import { NavLink } from "react-router-dom";

const pages = [
  { name: "Home", path: "/Homepage" },
  { name: "About Us", path: "/Aboutpage" },
  { name: "Departments", path: "/Departmentspage" },
  { name: "Doctors", path: "/Doctorspage" },
  { name: "Our Service Highlights", path: "/Facilitiespage" },
  { name: "Packages", path: "/packages" },
  { name: "News & Events", path: "/news-events" },
  { name: "Contact", path: "/contact" },
];

function NavbarMain() {
  return (
    <AppBar position="static" sx={{ backgroundColor: "#FFFFFF" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ padding: "10px" }}>
          <Grid container size={12} display={"flex"} alignItems="center">
            <Grid size={2}>
              <img src={logo} alt="logo" width={"180px"} />
            </Grid>
            <Grid size={10}>
              <Box
                sx={{
                  textWrap: "nowrap",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                {pages.map((page) => (
                  <Button
                    key={page.name}
                    id="text-13-600-15-Poppins"
                    sx={{
                      my: 2,
                      color: "#171859",
                      display: "block",
                    }}
                  >
                    <NavLink
                      to={page.path}
                      style={({ isActive }) => ({
                        textDecoration: "none",
                        color: isActive ? "#B07E2C" : "inherit",
                      })}
                    >
                      {page.name}
                    </NavLink>
                  </Button>
                ))}
              </Box>
            </Grid>
          </Grid>
          {/* </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavbarMain;
