import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import "./../Assets/css/pagescss/Homepage.css";
import Grid from "@mui/material/Grid2";
import Ologo from "./../Assets/images/O_logo.jpg";
import cardio from "./../Assets/images/Cardiology_Icon.jpg";
import urology from "./../Assets/images/Urology-Icon.jpg";
import generalmedi from "./../Assets/images/General-Medicine_Icon.jpg";

function Homepage() {
  const Department = [
    { label: "-- SELECT DEPARTMENT --" },
    { label: "INTERNAL MEDICINE" },
    { label: "GENERAL MEDICINE" },
    { label: "GYNAECOLOGY" },
    { label: "PAEDIATRICS" },
    { label: "OPHTHALMOLOGY" },
    { label: "ENT" },
    { label: "ORTHOPAEDICS" },
    { label: "DERMATOLOGY" },
    { label: "RADIOLOGY" },
    { label: "PSYCHIATRY" },
    { label: "PATHOLOGY" },
    { label: "CARDIOLOGY" },
    { label: "GASTROENTEROLOGY" },
    { label: "UROLOGY" },
  ];

  return (
    <>
      <div className="Homepage">
        <div className="Homebanner-container">
          <div className="Homebanner"></div>
          <div className="border"></div>
        </div>
      </div>

      <div className="home-service">
        <Grid
          container
          xl={12}
          display={"flex"}
          justifyContent={"center"}
          mt={8}
        >
          <Grid
            display={"flex"}
            alignItems={"center"}
            sx={{ color: "#171859" }}
          >
            <img src={Ologo} alt="o-logo" width={"40px"} height={"40px"} />
            <Typography id="text-30-600-15-Poppins">UR </Typography>
            <Typography id="text-30-600-15-Poppins" color="#B07E2C" ml={1}>
              SERVICES{" "}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={8} padding={"80px"} sx={{ color: "#171859" }}>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img src={cardio} alt="o-logo" width={"40px"} height={"40px"} />
              <Typography id="text-20-600-15-Poppins">Cardiology</Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              The cardiology department specializes in diagnosing, treating, and
              preventing heart-related diseases and conditions. It offers
              various services, including cardiac evaluations and treatment of
              coronary artery disease, arrhythmias, heart failure, and
              hypertension.
            </Typography>
          </Grid>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img src={urology} alt="o-logo" width={"40px"} height={"40px"} />
              <Typography id="text-20-600-15-Poppins"> Urology</Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              The Urology Department specializes in diagnosing and treating
              conditions related to the urinary tract and male reproductive
              system. It offers comprehensive services for both men and women,
              addressing issues such as urinary incontinence, kidney stones,
              urinary tract infections, and prostate problems.
            </Typography>
          </Grid>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img
                src={generalmedi}
                alt="o-logo"
                width={"40px"}
                height={"40px"}
              />
              <Typography id="text-20-600-15-Poppins">pulmonology</Typography>
            </Box>
            <Typography id="text-15-Poppins" textAlign={"justify"} mt={2}>
              The pulmonology department specializes in diagnosing and treating
              diseases related to the respiratory system, including the lungs
              and airways.
            </Typography>
          </Grid>
        </Grid>
        <div
          className="Appoinmentbanner"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            component="form"
            sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
            noValidate
            autoComplete="off"
            ml={10}
          >
            <div>
              <TextField
                id="text-15-Poppins"
                select
                defaultValue="Select Department"
                slotProps={{
                  select: {
                    native: true,
                  },
                }}
              >
                {Department.map((option) => (
                  <option key={option.label} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
          </Box>
        </div>
        <div className="border"></div>
      </div>
    </>
  );
}

export default Homepage;
