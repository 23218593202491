import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Grid from '@mui/material/Grid2';
import { Box, Button, Typography } from "@mui/material";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";

function Navbar1() {
  return (
    <AppBar position="static" sx={{ backgroundColor: "#e0e0e0", padding: '2px' }}>
      <Box sx={{ flexGrow: 2 }}>
        <Grid container spacing={5} display={"flex"} alignItems={'center'} justifyContent={'center'}>
          <Grid item xs={4}>
            <Box display={"flex"} gap={1}> 
              <WifiCalling3Icon sx={{color:'#B07E2C'}}/>
              <Typography sx={{color:'#171859', fontWeight:'600',}}>+974 12 34 56 78</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{color:'#171859', fontWeight:'600',}}>Address: P.O. Box: 123456, C Ring Road, Doha - Qatar</Typography>
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" sx={{backgroundColor:'#B07E2C', fontWeight: '600'}}>MAKE APPOINMENT</Button>
          </Grid>
        </Grid>
      </Box>
    </AppBar>
  );
}
export default Navbar1;
