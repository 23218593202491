import React, { createContext, useContext, useState, useEffect } from "react";
import Loader from "./../Assets/images/O_logo.jpg";
import { CircularProgress,Box,Backdrop } from "@mui/material";

const LoadingContext = createContext();
export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);
  const LOADER_TIME_LIMIT = 30000;
  useEffect(() => {
    let timer;
    if (loading) {
      timer = setTimeout(() => {
        stopLoading();
      }, LOADER_TIME_LIMIT);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [loading]);

  return (
    <LoadingContext.Provider value={{ loading, startLoading, stopLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};
export const useLoading = () => {
  return useContext(LoadingContext);
};

export const LoaderComponent = () => {
  const { loading} = useLoading();

  return (
    <Backdrop
    sx={{
      zIndex: (theme) => theme.zIndex.drawer + 1,
      backgroundColor: loading ? "rgba(0, 0, 0, 0.2)" : "transparent",
    }}
    open={loading}
  >
      <Box
        sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          borderRadius: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '91%',
            borderRadius: '50%',
            opacity: 0.5,
            border: '4px solid white',
            boxSizing: 'border-box',
          }}
        ></div>
        <CircularProgress size={60} thickness={3} style={{ color: '#0056CE' }} />
        <img
          src={Loader}
          alt="Loader"
          style={{
            position: 'absolute',
            top: 7.2,
            left: 5.5,
            width: '80%',
            height: '70%',
          }}
        />
      </Box>
    </Backdrop>
  );
};
