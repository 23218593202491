import React from "react";
import { Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Ologo from "./../Assets/images/O_logo.jpg";
import doctor1 from "./../Assets/images/Doctor1_with-background-0000.jpg";
import doctor2 from "./../Assets/images/Doctor2_with-background-0000.jpg";
import doctor3 from "./../Assets/images/Doctor3_with-background-0000.jpg";
import "./../Assets/css/pagescss/Doctorspage.css";

function Doctorspage() {
  return (
    <>
      <div className="Homepage">
        <div className="Homebanner-container">
          <div className="Doctorbanner"></div>
          <div className="border"></div>
        </div>
      </div>
      <div className="doctor-section">
        <Grid
          container
          xl={12}
          display={"flex"}
          justifyContent={"center"}
          mt={8}
        >
          <Grid
            display={"flex"}
            alignItems={"center"}
            sx={{ color: "#171859" }}
          >
            <img src={Ologo} alt="o-logo" width={"40px"} height={"40px"} />
            <Typography id="text-30-600-15-Poppins">UR </Typography>
            <Typography id="text-30-600-15-Poppins" color="#B07E2C" ml={1}>
              DOCTORS{" "}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={8} padding={"80px"} sx={{ color: "#171859" }}>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img src={doctor1} alt="o-logo" />
            </Box>
            <Typography
              id="text-15-Poppins"
              display={"flex"}
              justifyContent={"center"}
              textAlign={"justify"}
              mt={2}
            >
              Dr. ghhkhiokk
            </Typography>
            <Typography id="text-15-Poppins" color="#B07E2C" ml={1}>
              General Medicine
            </Typography>
          </Grid>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img src={doctor2} alt="o-logo" />
            </Box>
            <Typography
              id="text-15-Poppins"
              display={"flex"}
              justifyContent={"center"}
              textAlign={"justify"}
              mt={2}
            >
              Dr. ghhkhiokk
            </Typography>
            <Typography id="text-15-Poppins" color="#B07E2C" ml={1}>
              OB & GYNECOLOGY
            </Typography>
          </Grid>
          <Grid size={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <img src={doctor3} alt="o-logo" />
            </Box>
            <Typography
              id="text-15-Poppins"
              display={"flex"}
              justifyContent={"center"}
              textAlign={"justify"}
              mt={2}
            >
              Dr. ghhkhiokk
            </Typography>
            <Typography id="text-15-Poppins" color="#B07E2C" ml={1}>
              DERMATOLOGY
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Doctorspage;
