import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Homepage from "../Pages/Homepage";
import Aboutpage from "../Pages/About";
import Departmentspage from "../Pages/Departments";
import Doctorspage from "../Pages/OurDoctors";
import Facilitiespage from "../Pages/Facilities";

const AppRoutes = () => {
  const routes = [
    { path: "/homepage", element: <Homepage /> },
    { path: "/Aboutpage", element: <Aboutpage /> },
    { path: "/Departmentspage", element: <Departmentspage /> },
    { path: "/Doctorspage", element: <Doctorspage /> },
    { path: "/Facilitiespage", element: <Facilitiespage /> },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
      <Route path="*" element={<Navigate to="/homepage" />} />
    </Routes>
  );
};

export default AppRoutes;
